import React, { useState, useRef } from 'react';
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby"
import FsLightbox from 'fslightbox-react';
import { Carousel } from 'react-bootstrap';
import { LightboxImage, VideoPlayButton } from '../components/icon';
import ReactPlayer from "react-player";
import Modal from 'react-bootstrap/Modal'
import { Close, Search, VideoPause, Mute, UnMute } from '../components/icon';
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";
const PropertyGallery = (props) => {
    
    const data = useStaticQuery(graphql`
        query {
            propertyImage: file(relativePath: { eq: "property-detail.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            propertyImage2: file(relativePath: { eq: "property-detail2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            propertyImage3: file(relativePath: { eq: "property-detail3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const lightImages2 = props.propertyimages && props.propertyimages.map(img => img.url != undefined ? img.url : img.srcUrl);
    const imglength = lightImages2.length;

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1 
    });

    const openLighbox = (e) => {

        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: 1
        });
    }
    const [show, setShow] = useState(false);
    const [playvid, setPlayvideo] = useState(false);
    const vidRef = useRef(null);
    const bannervideolink = props.bannervideo;
    
    const playvideo = (ref) => {
      setPlayvideo(true)
    }
    const Slider = () => (
        <>
                    <li>
                        <button className="btn-tour test" onClick={openLighbox}>
                            <LightboxImage />
                        1/{imglength}</button>
                    </li>
                    {bannervideolink != null &&
                        <li class="banner_video_btn">
                            <button className="btn-play bannervideo-play" onClick={()=>{setShow(true);playvideo();}}>
                            <VideoPlayButton />
                            </button>
                        </li>
                    }
                    {playvid && show &&
                    <Modal dialogClassName="modal-full-video" show={show} fullscreen={true} onHide={() => setShow(false)}>
                    <div className="full-modal-close">
                        <a href="javascript:;" onClick={()=>{setShow(false);}}><Close/></a>
                    </div>
                    <ReactPlayer autoplay ref={vidRef} url= {bannervideolink} controls = {isIOS && isMobileOnly?true:false} autoplay={true} muted={false} playsinline={true} playing={true} onEnded={() =>{setPlayvideo(false)}}  width='100%'height='100%' />
                    </Modal>
                    }
</>

    )

    return (
        <div className="property-gallery">
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightImages2}
                type='image'
                slide={lightboxController.slide}
            />
            <Slider />
        </div>
    )
}

export default PropertyGallery;
